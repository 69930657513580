/* Global styles */
.about-us-container {
    font-family: 'Arial', sans-serif;
    color: #333;
}

/* Top background section */
.contact-header {
    background: linear-gradient(45deg, #4a148c, #8ec596);
    color: #fff;
    padding: 50px;
    text-align: center;
}

.contact-title {
    font-size: 48px;
    margin: 0;
    font-weight: 700;
}

/* Content area */
.content-container {
    display: flex;
    justify-content: space-between;
    padding: 40px 80px;
    background: #f9f9f9;
}

/* Left content section */
.left-content {
    width: 55%;
    padding-right: 20px;
}

.services-intro {
    margin-bottom: 30px;
}

.service-main-title {
    font-size: 28px;
    font-weight: 700;
    color: #333;
    margin-bottom: 15px;
}

.service-description {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
    margin-bottom: 10px;
}

/* Services grid styles */
.services-grid {
    display: flex;
    justify-content: space-between;
}

.service-card {
    text-align: center;
    padding: 0px;
    flex: 5;
    margin: 0 5px;
}

.service-line {
    border: none;
    border-top: 2px solid #333;
    width: 80%;
    margin: 15px auto;
}

.service-title-bold {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
}

.service-description {
    font-size: 16px;
    color: #333;
    margin-top: 10px;
}

/* Right form styles */
.right-form {
    width: 40%;
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.form-note {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
    display: block;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.submit-btn {
    padding: 14px 20px;
    background-color: #7b56a9;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.submit-btn:hover {
    background-color: #0056b3;
}

/* Footer styles */
.footer {
    text-align: center;
    padding: 20px;
    background-color: #f1f1f1;
    color: #333;
}

.team {
    margin-top: 50px; /* 增加上方间距 */
}