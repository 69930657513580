@import url('https://fonts.googleapis.com/css2?family=Georgia:wght@400;700&display=swap');
/* 引入 Georgia 字体 */


body {
  font-family: 'Georgia', serif;
  /* 使用 Georgia 字体 */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.text-center {
  text-align: center;
}

.wiki-logo {
  max-width: 350px;
  margin: 0;
  /* 将图片的上下边距设置为 0 */
}

.find-your-own-phage {
  font-size: 0.8em;
  /* 小字体 */
  margin-bottom: 0;
  /* 移除底部间距 */
}

.form-inline {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.form-control {
  width: 300px;
}

.btn-outline-success {
  margin-left: 10px;
}

.form-inline+.content {
  margin-top: 30px;
  /* 增加搜索栏和下方内容之间的间距 */
}

.chart-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

h1,
h2 {
  font-family: 'Georgia', sans-serif;
  /* 使用 Georgia 字体 */
  font-weight: bold;
  /* 使用粗体 */
}

h1 {
  margin-bottom: 10px;
  /* 减少标题和图片之间的间距 */
}

.chart-title {
  text-align: center;
  font-size: 1.2em;
  /* 调整字体大小 */
  margin-bottom: 10px;
  /* 标题与图表之间的间距 */
  font-weight: bold;
}

.body-font {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #333;
}

/* Spinner styles */
.loader {
  width: 120px;
  height: 22px;
  border-radius: 40px;
  color: #514b82;
  border: 2px solid;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  /* Center the spinner */
}

.loader::before {
  content: "";
  position: absolute;
  margin: 2px;
  width: 14px;
  top: 0;
  bottom: 0;
  left: -20px;
  border-radius: inherit;
  background: currentColor;
  box-shadow: -10px 0 12px 3px currentColor;
  clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%, -30px 50%);
  animation: l14 1s infinite linear;
}

@keyframes l14 {
  100% {
    left: calc(100% + 20px);
  }
}

.checkv-charts {
  border: 1px solid #ddd;
  /* 添加边框 */
  padding: 20px;
  /* 添加内边距 */
  border-radius: 20px;
  /* 可选：添加圆角 */
}

.select-quality {
  margin-left: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.info-icon {
  font-weight: bold;
  color: #000;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 0 5px;
  margin-left: 5px;
  cursor: pointer;
}

.custom-tooltip {
  background-color: #000;
  /* 设置背景颜色 */
  color: #fff;
  /* 设置字体颜色 */
  font-size: 4px;
  /* 设置字体大小 */
  font-family: Arial, sans-serif;
  /* 设置字体 */
  max-width: 400px;
  /* 设置最大宽度 */
  white-space: pre-wrap;
  /* 允许换行 */
  border-radius: 10px;
  /* 设置圆角 */
  padding: 8px;
  /* 设置内边距 */
}

.icon-spacing {
  margin-left: 8px;
  /* 根据需要调整间距 */
}

.metrics-charts.four-in-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.chart-container {
  flex: 0 0 calc(25% - 1em);
  margin: 0.5em;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}


/* Sidebar styles */
.content-wrapper {
  display: flex;
  margin-top: 20px;
  /* Adjust margin to make space for the fixed sidebar */
}

.sidebar {
  width: 180px;
  /* 调整左侧栏的宽度 */
  background-color: #f7f7f7;
  /* 改为你想要的背景颜色 */
  border-right: 1px solid #ddd;
  padding: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  color: #000;
  text-decoration: none;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

.main-content {
  margin-left: 50px;
  /* 调整以匹配左侧栏的宽度 */
  padding: 10px;
  flex-grow: 1;
  font-family: 'Arial', sans-serif;
  /* 使用 Arial 字体 */
}

.section-title {
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.phage-section {
  border: 1px solid #ddd;
  /* Add border */
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 8px;
  /* Add border radius */
  padding: 20px;
  /* Add padding */
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  /* Add margin */
  background-color: #f9f9f9;
  /* Add background color */
}

.table {
  margin-bottom: 15px;
}

.table th,
.table td {
  vertical-align: middle;
}

.table th {
  width: 20%;
}

.table td {
  width: 80%;
}

/* Existing styles remain unchanged */

.table.table-bordered {
  width: 100%;
  table-layout: fixed;
  /* Ensures even distribution of column widths */
}

.table.table-bordered th,
.table.table-bordered td {
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  /* Ensures long words will break and not overflow */
}

.table.table-bordered th {
  width: 20%;
  /* Adjust this value if necessary */
}

.table.table-bordered td {
  width: 20%;
  /* Adjust this value if necessary */
}

/* Adjusting chart container to ensure uniform size */
.chart-container {
  flex: 0 0 calc(25% - 1em);
  margin: 0.5em;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

/* Additional styles to ensure charts are uniform in height */
.chart-container h4.chart-title {
  margin-bottom: 10px;
}

.metrics-charts.four-in-row .chart-container {
  flex: 1 1 24%;
  /* Ensures that each chart takes up 24% of the row, with some margin */
}

.search-results-info {
  font-size: 14px;
}

.search-results-info .page-link {
  color: #007bff;
  cursor: pointer;
  margin: 0 5px;
  display: inline-block;
}

.search-results-info .page-link:hover {
  text-decoration: underline;
}

.search-results-info span {
  display: inline;
  /* 确保所有分页链接在同一行显示 */
}

.search-results-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* 确保内容在同一行显示 */
}

.search-results-info p {
  margin: 0;
}

.phage-icon {
  width: 50px;
  /* 设置图片宽度 */
  height: 50px;
  /* 设置图片高度 */
  margin-right: 10px;
  /* 设置右边距 */
}

.table.phage-results-table {
  width: 100%;
  table-layout: fixed;
}

.table.phage-results-table th,
.table.phage-results-table td {
  text-align: left;
  padding: 8px;
}

.table.phage-results-table th:nth-child(1),
.table.phage-results-table td:nth-child(1) {
  width: 3%;
}

.table.phage-results-table th:nth-child(2),
.table.phage-results-table td:nth-child(2) {
  width: 8%;
}

.table.phage-results-table th:nth-child(3),
.table.phage-results-table td:nth-child(3) {
  width: 8%;
}

.table.phage-results-table th:nth-child(4),
.table.phage-results-table td:nth-child(4) {
  width: 8%;
}

.table.phage-results-table th:nth-child(5),
.table.phage-results-table td:nth-child(5) {
  width: 8%;
}

.table.phage-results-table th:nth-child(6),
.table.phage-results-table td:nth-child(6) {
  width: 8%;
}

.table.phage-results-table th:nth-child(7),
.table.phage-results-table td:nth-child(7) {
  width: 7%;
}

.table.phage-results-table th:nth-child(8),
.table.phage-results-table td:nth-child(8) {
  width: 47%;
}

/* Custom select styling */
.custom-select {
  width: 105px; /* Set the width of the dropdown to be narrower */
  height: 38px; /* Control the height of the dropdown */
  border: 1px solid #ced4da; /* Border color */
  border-radius: 4px; /* Rounded corners */
  margin-right: 8px;
  padding: 6px 10px; /* Inner padding */
  font-size: 16px; /* Font size */
  background: #ffffff url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='gray' class='bi bi-caret-down-fill' viewBox='0 0 16 16'> <path d='M7.247 11.14l-4.796-5.481c-.566-.646-.106-1.659.753-1.659h9.592c.86 0 1.319 1.013.753 1.659l-4.796 5.48c-.383.437-1.122.437-1.505 0z'/></svg>")
    no-repeat right 10px center; /* Custom dropdown arrow */
  -webkit-appearance: none; /* Remove default styling */
  -moz-appearance: none; /* Remove default styling */
  appearance: none; /* Remove default styling */
}

.custom-select:focus {
  border-color: #09ac3fcd; /* Border color on focus */
  outline: none; /* Remove default focus effect */
  box-shadow: 0 0 0 0.2rem rgba(0, 141, 28, 0.25); /* Add focus shadow */
}