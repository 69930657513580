.search-filter-container {
  width: 100%; /* 调整宽度为页面的一半 */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.search-filter-container .field-group {
  margin-bottom: 10px;
}

.search-filter-container .field-group label {
  display: block;
  margin-bottom: 1px;
  font-weight: bold;
}

.search-filter-container .checkbox-group div {
  display: flex;
  align-items: center;
  margin-right: 10px;
  white-space: nowrap; /* 防止文字换行 */
}

.search-filter-container .checkbox-group div label {
  margin-left: 5px; /* 给标签和复选框之间添加一些间距 */
  align-items: center;
  font-weight: normal; /* 确保标签字体不是粗体 */
}

.search-filter-container .field-group input,
.search-filter-container .field-group select {
  width: calc(100% - 20px);
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-filter-container .field-group div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-filter-container .field-group div input {
  width: calc(48% - 4px);
}

.search-filter-container .field-group div label.min-max-label {
  width: auto;
  padding: 0 10px;
}

.search-filter-container button {
  width: 100%;
  padding: 10px;
  background-color: #93BBBF; /* 修改为浅绿色 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-filter-container button:hover {
  background-color: #38A6A6; /* 修改为深浅绿色 */
}

.accordion-button {
  width: 100%;
  background-color: #90ee90; /* 修改为浅绿色 */
  border: none;
  text-align: left;
  padding: 5px;
  font-size: 1.2em; /* 字体加大 */
  font-weight: bold; /* 加粗字体 */
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  color: white; /* 修改为白色字体 */
}

.accordion-button:after {
  content: '▲';
  float: right;
  margin-left: 10px;
}


.accordion-button.collapsed:after {
  content: '▼';
}

.accordion-content {
  padding: 10px;
  padding-top: 3px;
}

hr {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.search-results-info {
  font-size: 14px;
}

.search-results-info .page-link {
  color: #007bff;
  cursor: pointer;
  margin: 0 5px;
  display: inline-block;
}

.search-results-info .page-link:hover {
  text-decoration: underline;
}

.search-results-info span {
  display: inline; /* 确保所有分页链接在同一行显示 */
}

.search-results-info {
  display: flex;
  align-items: center;
}

.search-results-info p {
  margin: 0;
}