/* Nav icon container */
.nav-container {
    position: sticky;
    /* Adjust this to position from the top */
    top: 20px;
    /* Padding from the right */
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    /* Aligns the icon to the right */
    align-items: flex-end;
    /* Display it above everything else */
    z-index: 1000;
}

/* Nav icon lines */
.nav-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.nav-icon .line {
    width: 30px;
    height: 3px;
    background-color: #333;
    transition: all 0.3s ease;
}

.nav-icon.open .line:nth-child(1) {
    transform: rotate(45deg) translateY(8px);
}

.nav-icon.open .line:nth-child(2) {
    opacity: 0;
}

.nav-icon.open .line:nth-child(3) {
    transform: rotate(-45deg) translateY(-8px);
}

/* Menu container */
.menu {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;
    background-color: #333;
    width: 200px;
    /* Adjust width as needed */
    border-radius: 0 0 4px 4px;
    /* Rounded corners on the bottom */
    position: absolute;
    top: 40px;
    /* Position the menu right below the nav icon */
    right: 0;
    /* Align the menu to the right */
}

/* When the menu is open, expand its height */
.menu.show {
    max-height: max-content;
    /* Adjust based on the number of items */
}

/* Menu items */
.menu ul {
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
}

.menu ul li {
    margin: 10px 0;
    text-align: center;
    /* Center the text in the menu items */
}

.menu ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    display: block;
}

.menu ul li a:hover {
    background-color: #444;
    color: #ddd;
}